import React from "react";
import Link from "next/link";

import { Container, Row, Col } from "../Base/Layout";

import { FiMail, FiPhoneCall } from "react-icons/fi";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

import logo from "../../../assets/footer-logo-white.png";
import particleOne from "../../../assets/footer-particle.png";

import FotterWrapper from "./index.style";

const Footer = () => {
  return (
    <FotterWrapper>
      <img className="section__particle one" alt="img" src={particleOne} alt="appion app landing" />
      <Container>
            <div className="footer-widgets first" style={{display: 'flex'}}>
              <div>
                <Link className="footer-logo" href="/">
                  <img src={logo} alt="logo" style={{maxWidth: '400px'}}/>
                </Link>
                <ul style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="info">
                  <li>
                    <FiMail />
                    <a className="anchor" href="mailto:contact@ondiagram.com" target="_blank">
                      contact@ondiagram.com
                    </a>
                  </li>
                </ul>
              </div>
              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              <ul style={{textAlign: 'center'}} className="social">
                {/* <li>
                  <a className="anchor" href="https://facebook.com">
                    <FaFacebookF />
                  </a>
                </li> */}
                <li>
                  <a className="anchor" href="https://twitter.com/andreas_tzionis">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a className="anchor" href="https://www.linkedin.com/in/andreas-tzionis/">
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        <div className="footer-bottom">
          <ul className="footer-menu">
            <li>
              <Link className="anchor" href="/privacy">
                Privacy
              </Link>
            </li>
            <li>
              <Link className="anchor" href="/terms">
                Terms &amp; Condition
              </Link>
            </li>
            <li>
              <a className="anchor" href="mailto:andreas@ondiagram.com">
                Contact
              </a>
            </li>
          </ul>
          <p className="copyright-text">
            Diagram © 2021 | Icons by <a href="https://freepik.com" style={{color: 'white'}}>Freepik</a>
          </p>
        </div>
      </Container>
    </FotterWrapper>
  );
};

export default Footer;
