import React from "react";
import Link from "next/link";

import { useRouter } from "next/router";

import { FaRocket } from "react-icons/fa";

import Button from "../../Base/Button";


const ScrollspyMenu = ({ menuItems, setExpand, ...props }) => {

  const router = useRouter()

  const addAllClasses = [""];
  if (props.className) {
    addAllClasses.push(props.className);
  }
  return (
    <ul style={{alignItems: 'center'}} className={addAllClasses.join(" ")}>
      {menuItems.map((menu, index) => (
        <li
          key={index}
          className={
            menu.subItems !== undefined ? "nav-item has-dropdown" : "nav-item"
          }
          onClick={() => setExpand(false)}
        >
         
          <Link href={menu.path} >
            {menu.name}
          </Link>
        </li>
      ))}
      <li className="navbar-button-mobile-fix" style={{marginLeft: '40px', padding: 0}}>
        <Button className="banner-btn one" onClick={() => router.push('/app')}>
          <FaRocket className="icon-left" /> Get started
        </Button>
      </li>
    </ul>
  );
};

export default ScrollspyMenu;
