import React from "react";
import PropTypes from "prop-types";
import BtnStyle from './btn.style';

const Button = ({className, children, ...props}) => {
  return (
    <BtnStyle className={!className ? "appion__btn" : `appion__btn ${className}`} {...props}>
      {children}
    </BtnStyle>
  )
};

Button.propTypes = {
  as: PropTypes.oneOf(["button", "a",]), //--
  upperCase: PropTypes.bool, //--
};

export default Button;
