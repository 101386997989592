const Data = {
    menuItems: [
      {
        name: "Blog",
        path: "/blog",
        offset: "-500"
      },
      {
        name: "Uses",
        path: "/#features",
        offset: "-50"
      },
      {
        name: "Pricing",
        path: "/pricing",
        offset: "-500"
      },
      {
        name: "Stories",
        path: "/#testimonial",
        offset: "-50"
      },
      {
        name: "Faq",
        path: "/#faq",
        offset: "-50"
      }
    ]
  };
  export default Data;
  