import React, { useState, useEffect } from "react";
import Link from "next/link";
import { FaBars } from "react-icons/fa";

import { Container } from "../Base/Layout";
import logo from "../../../assets/logo.png";

import { FaTimes } from "react-icons/fa";

import Data from "./utility/data.js";
import ScrollspyMenu from "./utility/ScrollspyMenu.js";

import NavigationWrap from "./navigation.style";

const NotificationBar = () => {
  const [showNotification, setShowNotification] = useState(true);
  return (
    <>
      {showNotification && <div style={{backgroundColor: '#2A2039', color: 'white', padding: 8, textAlign: 'center', display: 'flex', alignItems: 'center'}}>
        <span style={{marginLeft: 'auto', marginRight: 'auto'}}>4/3/2021 - New Code block is released! Allows to add custom scripts in your endpoints! 🎉</span>
        <FaTimes onClick={() => setShowNotification(false)} className="hover" style={{marginRight: '16px'}}/>
      </div>}
    </>
  )
}

const Navigation = () => {
  const [expand, setExpand] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 50 ? setScroll(true) : setScroll(false)
    );
  }, [ ]);
  return (
    <>
    <NavigationWrap className={`nav-block ${scroll ? "scrolled" : ""}`}>
      <NotificationBar/>
      <Container style={{display: 'flex'}}>
        <div className="navbar-wrap" style={{cursor: 'pointer'}}>
          <Link href="/" className="logo">
            <img src={logo} alt="prime app landing" style={{marginTop: '10px', maxWidth: '200px'}}/>
          </Link>
          <nav className="nav">
            <FaBars
              className="mobile-menu-icon"
              onClick={() => setExpand(!expand)}
            />
            <ScrollspyMenu
              setExpand={setExpand}
              className={`collapsed ${expand ? "is-expanded" : ""}`}
              menuItems={Data.menuItems}
            />
          </nav>
        </div>
      </Container>
    </NavigationWrap>
    </>
  );
};

export default Navigation;
